import React from 'react';
import Img from 'gatsby-image';
import { DialogOverlay, DialogContent } from '@reach/dialog';

export const Lightbox = ({ image, title, alt, className }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const open = (e) => {
    e.preventDefault();

    setIsOpen(true);
  };

  const close = () => setIsOpen(false);

  return (
    <>
      <a href={image.childImageSharp.original.src} className={className} onClick={open}>
        <Img fluid={{ ...image.childImageSharp.fluid, aspectRatio: 1 }} title={title} alt={alt} />
      </a>

      {isOpen && (
        <DialogOverlay onDismiss={close} className="z-50">
          <DialogContent>
            <button onClick={close} className="w-full">
              <Img fluid={image.childImageSharp.fluid} title={title} alt={alt} />
            </button>
          </DialogContent>
        </DialogOverlay>
      )}
    </>
  );
};
