import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Layout } from '../components/Layout';
import { Meta } from '../components/Meta';
import { Main } from '../components/Main';
import { Content } from '../components/Content';
import { Lightbox } from '../components/Lightbox';

import logo from '../images/servis-oprava.jpg';

const title = 'Servis, oprava a obnova kuchynských liniek';

const data = [
  <>
    Nadstavba vrchnej časti, výmena dvierok a závesov,
    <br />
    výmena výsuvov, doplenenie LED osvetlenia
  </>,
  'Výmena výsuvov a zásuviek',
  'Výmena dvierok, pracovnej dosky a zásteny, vloženie myčky a drezu',
  'Výmena pracovnej dosky a drezu',
  'Výmena dvierok',
  'Výmena dvierok, pracovnej dosky, vloženie vstavanej rúry',
];

export default () => {
  const {
    allFile: { nodes: images },
  } = useStaticQuery(graphql`
    {
      allFile(sort: { fields: name, order: DESC }, filter: { dir: { regex: "/servis/" } }) {
        nodes {
          ...fluidImage
        }
      }
    }
  `);

  return (
    <Layout>
      <Meta title={title} />
      <Main>
        <h1>{title}</h1>
        <Content>
          <img src={logo} alt="servis a oprava logo" className="hidden sm:block float-right ml-8" />
          <h3>Výmena</h3>
          <ul className="list-disc list-inside mb-4">
            <li>dvierok</li>
            <li>pracovnej dosky</li>
            <li>zásteny</li>
            <li>závesov</li>
            <li>výsuvov</li>
            <li>a pod.</li>
          </ul>
          <h3>Zabudovanie</h3>
          <ul className="list-disc list-inside mb-4">
            <li>spotrebičov</li>
            <li>drezov</li>
            <li>led osvetlenia</li>
          </ul>
          <h3>Možnosti prevedenia dvierok</h3>
          <ul className="list-disc list-inside">
            <li>fóliované</li>
            <li>striekané</li>
            <li>patinované</li>
            <li>dýhované</li>
          </ul>
        </Content>
        {data.map((heading, i) => (
          <Content>
            <h2>{heading}</h2>
            <div className="flex flex-wrap -m-2">
              {images
                .filter((_, j) => j >= i * 2 && j < (i + 1) * 2)
                .map((image, k) => (
                  <Lightbox
                    image={image}
                    key={k}
                    className="w-1/2 p-2"
                    title={k === 0 ? 'pred' : 'po'}
                    alt={k === 0 ? 'kuchynská linka pred servisom' : 'kuchynská linka po servise'}
                  />
                ))}
            </div>
          </Content>
        ))}
      </Main>
    </Layout>
  );
};
